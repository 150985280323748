<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Profiles</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    placeholder="Search Word"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <!-- <div class="col-2">
                    <div class="form-group">
                      <label for=""><b>User Type</b></label>
                      <select name="" id="" v-model="user_type" class="form-control form-control-sm">
                        <option value="all">All</option>
                        <option value="SUPERUSER">SUPERUSER</option>
                          <option value="trainer">trainer</option>
                          <option value="student">student</option>
                      </select>
                    </div>
                  </div> -->
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>User</th>
                  <th
                    :class="{
                      active_sort: sort_by == 'profile_type' || sort_by == '-profile_type',
                    }"
                    @click="sortBy('profile_type')"
                  >
                    PROFILE TYPE
                    <i
                      :class="{
                        'fa-sort': sort_by != 'profile_type',
                        'fa-sort-up': sort_by == 'profile_type',
                        'fa-sort-down': sort_by == '-profile_type',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                  >
                    COURSES
                  </th>
                  <th
                    :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')"
                  >
                    STATUS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td>{{ item.user?item.user.first_name:'-' }}</td>
                      <td>{{ item.profile_type }}</td>
                      <td>{{ item.courses.length }}</td>
                      <td>
                        <span class="text-success" v-if="item.status"
                          ><b>Active</b></span
                        >
                        <span class="text-warning" v-else><b>INACTIVE</b></span>
                        <!-- <span
                          class="fa fa-pencil edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item)"
                        /> -->
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="6"
              ></footer-table>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../mixins/dataMixin";
export default {
  data() {
    return {
      serviceModule: "profiles",
      defaultPayload: {
        title: null,
        description: null,
        price: null,
        credits: null,
        order: null,
        validity: null,
        rules: null,
        status: true,
      },
    };
  },
  mixins: [DataMixin],
  methods: {
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (
        that.payload.description === null ||
        that.payload.description === ""
      ) {
        this.error.description = "Description is required";
        proceed = false;
      }
      if (that.payload.price === null || that.payload.price === "") {
        this.error.price = "Price is required";
        proceed = false;
      }
      if (that.payload.credits === null || that.payload.credits === "") {
        this.error.credits = "Credits is required";
        proceed = false;
      }
      if (that.payload.validity === null || that.payload.validity === "") {
        this.error.validity = "Validity is required";
        proceed = false;
      }
      if (that.payload.order === null || that.payload.order === "") {
        this.error.order = "Order is required";
        proceed = false;
      }
      if (that.payload.title === null || that.payload.title === "") {
        this.error.title = "Title is required";
        proceed = false;
      }
      return proceed;
    },
  },
};
</script>
<style lang="stylus" scoped></style>